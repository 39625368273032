/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  Chip,
} from '@nextui-org/react';
import { useInvoice } from 'application/modules/subscription';
import dayjs from 'dayjs';
import { Invoice as InvoiceModel } from 'domain/modules/subscription';
import { transformSecondsToMs } from 'presentation/utils/dateInSecondsToMS';
import { PDFGenerator } from './generatePdf';

export const Invoice = ({ toShowPricing }: { toShowPricing: boolean }) => {
  const { invoices, get } = useInvoice();
  const [page, setPage] = React.useState(1);
  const rowsPerPage = 4;

  useEffect(() => {
    if (!invoices) {
      get();
    }
  }, [get, invoices]);

  const pages = Math.ceil((invoices || []).length / rowsPerPage);

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return (invoices || []).slice(start, end);
  }, [page, invoices]);

  const renderCell = React.useCallback((user: InvoiceModel, columnKey: any) => {
    switch (columnKey) {
      case 'id':
        return user.id;
      case 'billingDate':
        return dayjs(transformSecondsToMs(user.paid_at)).format('DD MMM YYYY');
      case 'plan':
        return dayjs(transformSecondsToMs(user.paid_at)).format('DD MMM YYYY');
      case 'amount':
        return toShowPricing
          ? `${(user.currency_code || '').toUpperCase()} ${user.sub_total / 100}`
          : '-';
      case 'status':
        return (
          <Chip className="capitalize" size="sm" variant="flat">
            {user.line_items[0].description}
          </Chip>
        );
      case 'download':
        return toShowPricing ? <PDFGenerator invoiceId={user.id} /> : '';
      default:
        return (user as any)[columnKey as any] as any;
    }
  }, []);

  return (
    <Table
      aria-label="Example table with client side pagination"
      bottomContent={
        <div className="flex w-full justify-end">
          <Pagination
            isCompact
            showControls
            showShadow
            page={page}
            total={pages}
            classNames={{
              cursor: 'bg-black text-white',
            }}
            onChange={(page) => setPage(page)}
          />
        </div>
      }
      classNames={{
        wrapper: 'h-full rounded shadow-sm border-1 border-slate-100',
        base: 'h-full',
      }}
    >
      <TableHeader>
        <TableColumn key="id">Invoice ID</TableColumn>
        <TableColumn key="billingDate">Billing Date</TableColumn>
        <TableColumn key="plan">Plan</TableColumn>
        <TableColumn key="amount">Amount</TableColumn>
        <TableColumn key="status">Status</TableColumn>
        <TableColumn key="download">{''}</TableColumn>
      </TableHeader>
      <TableBody items={items}>
        {(item) => (
          <TableRow key={item.id}>
            {(columnKey) => <TableCell>{renderCell(item, columnKey as any)}</TableCell>}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
