import { useEffect, useState } from 'react';
import { Button } from 'presentation/components';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
  Tooltip,
  cn,
  useDisclosure,
} from '@nextui-org/react';
import './style.css';
import { useSubscription } from 'application/modules/subscription';
import { AddonCreditsCard } from '../../components/AddonCreditsCard';
import { Carousel } from 'react-responsive-carousel';
import { PaymentStatusModal } from '../../components/PaymentStatusModal';
import { AddonPlan } from 'domain/modules/subscription/models/plans.model';

interface IProps {
  isWorkboard?: boolean;
  isDisabled?: boolean;
  refreshAll?: (arg0: () => void) => void;
  isUpgrade?: boolean;
}

export const SubscribeToCredits = ({ isWorkboard, refreshAll, isUpgrade }: IProps) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const {
    isOpen: paymentModalIsOpen,
    onOpen: paymentModalOnOpen,
    onClose: paymentModalOnClose,
  } = useDisclosure();
  const { getAddonPlans, addOnPlans, get, subscription, getPlans, plans } = useSubscription();
  const [cbInstance, setCbInstance] = useState<any>(null);
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const [checkedOutPlan, setCheckedOutPlan] = useState<AddonPlan | null>(null);

  useEffect(() => {
    get();
    getPlans();
  }, []);

  useEffect(() => {
    if (plans && subscription) {
      if (subscription && ['ACTIVE', 'CANCELLED'].includes(subscription.status)) {
        getAddonPlans();
      }
    }
  }, [subscription]);

  useEffect(() => {
    if (window && isOpen) {
      console.log('Initializing chargebee');
      const _cbInstance = (window as any).Chargebee.init({
        site: import.meta.env.VITE_CHARGEBEE_SITE,
        publishableKey: import.meta.env.VITE_CHARGEBEE_PUBLISHABLE_KEY,
      });
      setCbInstance(_cbInstance);
    }

    return () => {
      if (cbInstance) {
        try {
          cbInstance.close();
        } catch (ex) {
          console.log('Do Nothing');
        }
      }
    };
  }, [isOpen]);

  if (!subscription || !['ACTIVE', 'CANCELLED'].includes(subscription.status)) {
    return null;
  }

  return (
    <>
      {isUpgrade ? (
        <Button className="bg-black text-white rounded-full" size="sm" onPress={onOpen}>
          Upgrade
        </Button>
      ) : (
        <Tooltip
          content={`You have already used your add-on quota.`}
          placement="bottom"
          isDisabled={subscription.number_of_addons > 0 || subscription.is_upgrade_scheduled}
        >
          <div>
            <Button
              onPress={onOpen}
              startContent={<i className="ri-add-line" />}
              className={cn(
                'text-black font-medium',
                isWorkboard ? 'bg-[#F0F0F0]' : 'bg-[#99CF63]'
              )}
              size="md"
              isDisabled={subscription.number_of_addons < 1 && !subscription.is_upgrade_scheduled}
            >
              Design Credits
            </Button>
          </div>
        </Tooltip>
      )}
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="5xl"
        scrollBehavior="normal"
        hideCloseButton
        classNames={{
          base: 'w-[90vw] md:w-[80%]',
          body: 'px-0 md:px-6 flex flex-row gap-0 rounded-xl',
        }}
        placement="center"
      >
        <ModalContent>
          <>
            <ModalHeader className="flex items-center justify-between flex-col gap-4 md:flex-row md:gap-2">
              <div className="flex justify-between items-center w-full md:w-[40%]">
                <h3 className=" text-black font-semibold text-2xl md:text-4xl md:font-bold">
                  Buy <br /> More Credits
                </h3>
                <Button
                  variant="light"
                  className="text-[#222] text-[16px] py-2 px-4 data-[hover]:bg-transparent block md:hidden"
                  onPress={() => onClose()}
                >
                  Skip
                </Button>
              </div>
              <div className="text-[#828282] text-[16px] font-medium leading-[18px] w-full text-left hidden md:w-[50%] md:block">
                <h5 className="text-[#0D1216] text-lg font-medium mb-2">Why Buy Credits?</h5>

                <ul className="px-6 text-[#828282] text-sm font-medium">
                  <li className="list-disc">
                    Supplement your main subscription with extra design credits.
                  </li>
                  <li className="list-disc">Tailor your credit balance to your specific needs.</li>
                  <li className="list-disc">
                    Enjoy continuous, uninterrupted access to Top Notch Designs
                  </li>
                </ul>
              </div>
            </ModalHeader>
            <ModalBody className="pb-4">
              {isCheckingOut ? (
                <div className="flex items-center gap-4">
                  <h1>Processing payment</h1>
                  <Spinner color="default" />
                </div>
              ) : (
                <>
                  <div className="gap-12 w-full px-6 md:px-0 md:flex flex-nowrap justify-between h-full">
                    {addOnPlans.map((plan) => (
                      <AddonCreditsCard
                        key={plan.id}
                        data={plan}
                        cbInstance={cbInstance}
                        onSuccess={() => {
                          refreshAll &&
                            refreshAll(() => {
                              onClose();
                              setIsCheckingOut(false);
                              setCheckedOutPlan(plan);
                              paymentModalOnOpen();
                              get();
                            });
                        }}
                        setIsCheckingOut={setIsCheckingOut}
                      />
                    ))}
                  </div>
                  <div className="block w-full md:hidden">
                    <Carousel showArrows={false} swipeable={true} showStatus={false}>
                      {addOnPlans.map((plan) => (
                        <div className="px-6 max-w-[90vw] pb-10" key={plan.id}>
                          <AddonCreditsCard
                            key={plan.id}
                            data={plan}
                            cbInstance={cbInstance}
                            onSuccess={() => {
                              refreshAll && refreshAll(() => onClose());
                            }}
                            setIsCheckingOut={setIsCheckingOut}
                          />
                        </div>
                      ))}
                    </Carousel>
                  </div>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <div className="flex items-center w-full md:justify-between pb-4">
                <h4 className="text-[#626F86] text-sm italic font-semibold w-full md:w-[40%]">
                  *Kindly note that this credit package is valid for one month from the date of
                  purchase.
                </h4>
              </div>
            </ModalFooter>
          </>
        </ModalContent>
      </Modal>
      <PaymentStatusModal
        isOpen={paymentModalIsOpen}
        onClose={() => {
          paymentModalOnClose();
          setCheckedOutPlan(null);
        }}
        purchaseType="credits"
        planType={checkedOutPlan?.name || ''}
        creditsCount={checkedOutPlan?.metadata?.credits || 0}
      />
    </>
  );
};
