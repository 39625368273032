import { useAuth } from 'application/modules';
import { getUser } from 'application/modules/auth/auth.thunks';
import { useAppDispatch } from 'application/shared';
import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

interface IProps {
  component: JSX.Element;
}

export const AuthGuard = ({ component }: IProps) => {
  const { user, beUser, isLoading } = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let userRole = '';

  useEffect(() => {
    if (!beUser) {
      dispatch(getUser());
    }
  }, [isLoading, beUser, dispatch]);

  useEffect(() => {
    if (window.location.pathname === '/') {
      navigate('/workboard');
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    userRole = JSON.parse(user?.reloadUserInfo?.customAttributes || '{}').role;
    if (
      user &&
      beUser &&
      !window.location.pathname.includes('/company/onboarding') &&
      userRole !== 'admin'
    ) {
      if (!beUser.company_id || beUser.company_step_number !== 'complete') {
        navigate(`/company/onboarding/step${beUser.company_step_number}`);
      }
    }
  }, [user, beUser, navigate, userRole]);

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (!beUser) {
    return null;
  }

  try {
    if (user) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      userRole = JSON.parse(user.reloadUserInfo?.customAttributes || '{}').role;
      if (userRole === 'admin') {
        window.location.href = '/admin-app';
      }
    } else {
      return <h1>You are not authorised.</h1>;
    }
  } catch (error) {
    <Navigate to="/login" replace />;
  }

  if (
    user &&
    beUser &&
    !window.location.pathname.includes('/company/onboarding') &&
    (!beUser.company_id || beUser.company_step_number !== 'complete')
  ) {
    return null;
  }

  return <React.Fragment>{component}</React.Fragment>;
};
