/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Carousel } from 'react-responsive-carousel';
import inspiration_loader from 'presentation/assets/animations/inspiration_loader.json';
import { Plan } from 'domain/modules/subscription/models/plans.model';
import { Button } from 'presentation/components/Button';
import { Player } from '@lottiefiles/react-lottie-player';
import { cn, Spinner } from '@nextui-org/react';
import recommended_tag from 'presentation/assets/recommended_tag.svg';
import { useEffect, useRef, useState } from 'react';
import { useAuth } from 'application/modules';
import { useCheckout } from 'application/modules/subscription/hooks/useCheckout';
import { subscriptionService } from 'infra/api';
import { Subscription } from 'domain/modules';

interface IProps {
  plans: Plan[];
  isFetchingPlans: boolean;
  onSuccess: () => void;
  subscription: Subscription;
}

export const PlanList = ({ isFetchingPlans, plans, onSuccess, subscription }: IProps) => {
  const { beUser } = useAuth();
  const cbInstance = useRef<any>(null);

  useEffect(() => {
    if (!isFetchingPlans && window && beUser) {
      cbInstance.current = (window as any).Chargebee.init({
        site: import.meta.env.VITE_CHARGEBEE_SITE,
        publishableKey: import.meta.env.VITE_CHARGEBEE_PUBLISHABLE_KEY,
      });
    }
  }, [isFetchingPlans]);

  if (!plans || isFetchingPlans) {
    return (
      <div className="flex items-center justify-center">
        <Player
          src={inspiration_loader}
          className="player"
          loop
          autoplay
          style={{
            height: '50vh',
          }}
        />
      </div>
    );
  }

  const upgradePlan = async (priceId: string) => {
    subscriptionService
      .UpdatePlan(subscription?.subscription_id, priceId)
      .then((res) => {
        alert('Your plan has been updated will reflect from the next billing cycle');
        onSuccess();
      })
      .catch((err) => {
        console.log(err, 'update plan error');
        alert('Something went wrong.');
      });
  };

  return (
    <PlansContainer>
      {plans &&
        plans.map((plan, index) => (
          <div
            key={plan.id}
            className={
              !plan.isDisable
                ? 'bg-[#F2F6FB] rounded-xl px-4 py-5 flex flex-col relative pb-8 justify-between w-[33%]'
                : 'bg-[#F2F6FB] opacity-40 rounded-xl px-4 py-5 flex flex-col relative pb-8 justify-between w-[33%]'
            }
          >
            {index === 1 ? (
              <div className="absolute top-3 right-[1px]">
                <img src={recommended_tag} alt="recommended_tag" />
              </div>
            ) : null}
            <div className="flex flex-col gap-3">
              <div>
                <h4 className="text-black uppercase font-medium text-left">{plan.name}</h4>
                <h2 className="text-black text-2xl text-left">
                  <span className="text-3xl md:text-5xl font-bold">
                    <span className="text-small font-semibold pr-1">
                      {plan.price.currency_code}
                    </span>
                    {plan.price.price / 100}
                  </span>
                  /{plan.price.period_unit}
                </h2>
              </div>
              <div className="text-black text-xl rounded-xl py-0.5 border-2 text-center">
                {plan.metadata.credits} Design Credits
              </div>
              <h5>{plan.metadata.target_audience}</h5>
              <div>
                <h4 className="font-bold mb-2">Features:</h4>
                <ul className="list-disc">
                  {plan.metadata.features.map((feature, index) => {
                    return (
                      <li key={index} className="text-black text-sm leading-[20px] pb-3 ml-[20px]">
                        {feature}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <Button
              variant="solid"
              radius="full"
              className={cn('bg-black text-white text-center', 'px-16 py-[15px]')}
              onClick={() => upgradePlan(plan.price.id)}
              disabled={plan.isDisable}
            >
              Choose Plan
            </Button>
          </div>
        ))}
    </PlansContainer>
  );
};

const PlansContainer = ({ children }: any) => {
  return (
    <>
      <div className="gap-6 w-full px-6 md:px-0 hidden md:flex flex-nowrap justify-between">
        {children}
      </div>
      <div className="block w-full overflow-auto px-6 md:px-0 md:hidden plan-carousel">
        <Carousel showArrows={false} swipeable={true} showStatus={false}>
          {children}
        </Carousel>
      </div>
    </>
  );
};
