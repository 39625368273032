import { Progress, cn } from '@nextui-org/react';
import dayjs from 'dayjs';
import { useWorkboard } from 'application/modules/projects';
import { useSubscription } from 'application/modules/subscription';
import { TaskStatusType } from 'domain/modules';
import { Button } from 'presentation/components';
import { KanbanBoard } from 'presentation/components/KanbanBoard';
import { NoSubscription } from 'presentation/components/KanbanBoard/components/NoSubscription';
import { CreateProject } from 'presentation/modules/company/screens/createProject';
import { SubscribeToCredits } from 'presentation/modules/subscription/screens/subscribeToCredits';
import { useEffect, useState } from 'react';
import { PlanNoticeBanner } from 'presentation/components/PlanNoticeBanner';
import { PaymentStatusModal } from 'presentation/modules/subscription/components/PaymentStatusModal';
import { PaymentProcessing } from 'presentation/modules/subscription/components/PaymentProcessing';
import { transformSecondsToMs } from 'presentation/utils/dateInSecondsToMS';

import './style.css';
import ChatWrapper from 'presentation/components/DiscordFloatButton';

export type ModalKeyType = 'success' | 'processing' | 'planlist' | 'failed' | '';

export const WorkboardScreen = () => {
  const { getAll, updateTaskStatus } = useWorkboard();
  const {
    getPlans,
    plans,
    get,
    subscription,
    isFetchingSubscription,
    isFetchingPlans,
    getIsPlanPurchased,
    getIsCreditsPurchased,
  } = useSubscription();

  const [activeModalKey, setActiveModalKey] = useState<ModalKeyType>('');

  const [timeRemaining, setTimeRemaining] = useState(-1);

  useEffect(() => {
    getAll();
    getPlans();
    get()
      .then((res) => {
        if (!res) {
          setActiveModalKey('planlist');
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
  }, []);

  const updateProjectStatus = async (projectId: string, status: TaskStatusType) => {
    await updateTaskStatus(projectId, status);
    getAll();
    get();
  };

  useEffect(() => {
    if (subscription) {
      const endDate = dayjs(transformSecondsToMs(subscription.period_end_date));
      const currentDate = dayjs();
      const timeDifference = endDate.diff(currentDate, 'days');

      console.log(timeDifference);

      if (isNaN(timeDifference)) {
        setTimeRemaining(-1);
      } else {
        setTimeRemaining(timeDifference);
      }
    }
  }, [subscription]);

  return (
    <>
      <ChatWrapper subscription={subscription}>
        <div className="h-full w-full flex flex-col items-center overflow-x-hidden">
          <PlanNoticeBanner
            subscription={subscription}
            timeRemaining={timeRemaining}
            onOpen={() => setActiveModalKey('planlist')}
          />
          <div className="w-full pl-10 pr-6 pt-7 flex items-start justify-between">
            <h1 className="font-bold text-3xl 2xl:text-4xl">My Workboard</h1>
            <div className="flex flex-col items-end">
              <div className="flex gap-4 items-center mb-3 pr-2">
                {subscription && !subscription.free_subscriber && (
                  <SubscribeToCredits isWorkboard refreshAll={getIsCreditsPurchased} />
                )}
                <CreateProject
                  Trigger={({ open }) => {
                    return (
                      <Button
                        className={cn('button_background', 'bg-[#99CF63] text-black font-medium')}
                        onClick={() => {
                          if (!isFetchingSubscription && !subscription) {
                            setActiveModalKey('planlist');
                          } else if (subscription) {
                            open();
                          }
                        }}
                      >
                        Create Project
                      </Button>
                    );
                  }}
                />
              </div>

              {subscription ? (
                <div className="flex items-center justify-between px-2 gap-1">
                  <Progress
                    isStriped
                    color="default"
                    value={(subscription.used_credits / subscription.total_purchased_credits) * 100}
                    className="min-w-[175px] max-w-[200px] h-2 progress-loader"
                    classNames={{
                      track: 'bg-[#F0F0F0]',
                    }}
                  />
                  <p className="text-[#828282] text-xs font-medium min-w-[215px] text-right">
                    {subscription.used_credits} of {subscription.total_purchased_credits} Design
                    credits used
                  </p>
                </div>
              ) : null}
            </div>
          </div>
          <div className="w-full grow">
            <KanbanBoard
              updateProjectStatus={updateProjectStatus}
              subscription={subscription}
              getSubscriptionData={get}
              isFetchingSubscription={isFetchingSubscription}
              onOpen={() => setActiveModalKey('planlist')}
            />
          </div>
        </div>

        <NoSubscription
          isOpen={activeModalKey === 'planlist'}
          plans={plans}
          isFetchingPlans={isFetchingPlans}
          onCloseSubscription={(status: string) => {
            if (status === 'skip') setActiveModalKey('');
            else if (status === 'close') setActiveModalKey('planlist');
            else {
              setActiveModalKey('processing');
              getIsPlanPurchased((purchaseStatus: ModalKeyType) => {
                setActiveModalKey(purchaseStatus);
              });
            }
          }}
        />
        <PaymentStatusModal
          isOpen={activeModalKey === 'success' || activeModalKey === 'failed'}
          paymentStatus={activeModalKey}
          onClose={() => setActiveModalKey('')}
          purchaseType="plans"
          planType={subscription?.name || ''}
          creditsCount={0}
        />
        <PaymentProcessing isOpen={activeModalKey === 'processing'} />
      </ChatWrapper>
    </>
  );
};
