import { Avatar, Skeleton } from '@nextui-org/react';
import { useAuth } from 'application/modules';
import { useWorkboard } from 'application/modules/projects';
import { Project, Subscription } from 'domain/modules';
import { SubscribeToCredits } from 'presentation/modules/subscription/screens/subscribeToCredits';
import { useEffect, useMemo } from 'react';

interface IProps {
  isFetchingSubscription: boolean;
  subscription: Subscription | null;
  getIsCreditsPurchased: (arg0: () => void) => void;
}

export const AccountSidePanel = ({
  isFetchingSubscription,
  subscription,
  getIsCreditsPurchased,
}: IProps) => {
  const { beUser } = useAuth();
  const { getAll, projectsList } = useWorkboard();

  useEffect(() => {
    getAll();
  }, []);

  const completedProjectsCount = useMemo(() => {
    return projectsList.filter((project: Project) => project.status === 'COMPLETED').length;
  }, [projectsList]);

  return (
    <>
      <Avatar
        as="button"
        className="w-36 h-36 text-large m-9"
        color="primary"
        name={beUser?.name}
        src={beUser?.profile_picture}
      />
      <h1 className="text-3xl font-bold">{beUser?.name}</h1>
      <div className="flex items-center gap-6 my-12 w-full justify-around px-6">
        <div className="flex flex-col items-center">
          <Skeleton isLoaded={!isFetchingSubscription} className="min-w-10 rounded-full">
            <p className="text-base font-semibold align text-center">{completedProjectsCount}</p>
          </Skeleton>
          <p className="text-xs font-light text-center">
            Design Projects
            <br />
            Completed
          </p>
        </div>
        <div className="h-8 border-r-[2px] border-[#6C6C6C] border-solid;" />
        <div className="flex flex-col items-center">
          <Skeleton isLoaded={!isFetchingSubscription} className="min-w-10 rounded-full">
            <p className="text-base font-semibold text-center">{subscription?.used_credits || 0}</p>
          </Skeleton>
          <p className="text-xs font-light text-center">
            Credits
            <br />
            Used
          </p>
        </div>
        <div className="h-8 border-r-[2px] border-[#6C6C6C] border-solid;" />
        <div className="flex flex-col items-center">
          <Skeleton isLoaded={!isFetchingSubscription} className="min-w-10 rounded-full">
            {' '}
            <p className="text-base font-semibold text-center">
              {subscription &&
              subscription.total_purchased_credits !== undefined &&
              subscription.used_credits !== undefined
                ? subscription.total_purchased_credits - subscription.used_credits
                : '-'}
            </p>
          </Skeleton>
          <p className="text-xs font-light text-center">
            Credits
            <br />
            Available
          </p>
        </div>
      </div>
      {subscription && !subscription.free_subscriber ? (
        <SubscribeToCredits refreshAll={getIsCreditsPurchased} />
      ) : null}
    </>
  );
};
