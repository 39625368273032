import { Button, Card, CardBody, Progress, Skeleton, useDisclosure } from '@nextui-org/react';

import './style.css';
import { useSubscription } from 'application/modules/subscription';
import { useEffect } from 'react';
import { Invoice } from '../../components/Invoice';
import { UpgradeSubscription } from 'presentation/components/UpgradeSubscription/UpgradeSubscription';

export const PlansScreen = () => {
  const { get, subscription, isFetchingSubscription, getPlans, plans, isFetchingPlans } =
    useSubscription();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (!subscription) {
      get();
      getPlans();
    }
  }, []);

  const valuePercent = () => {
    if (
      subscription &&
      subscription.used_credits !== undefined &&
      subscription.total_purchased_credits !== undefined
    ) {
      return (subscription.used_credits / subscription.total_purchased_credits) * 100;
    }

    return 0;
  };

  const upgradePlan = () => {
    const indexOfCurrentPlan = plans.findIndex((plan) => plan.price.id === subscription?.name);
    return plans.map((plan, index) => {
      if (index > indexOfCurrentPlan) {
        return {
          ...plan,
          isDisable: false,
        };
      } else {
        return {
          ...plan,
          isDisable: true,
        };
      }
    });
  };

  if (!isFetchingSubscription && !subscription) {
    return (
      <div className="flex flex-col gap-4 px-6 h-full">
        <h1 className="text-xl font-semibold">You have not subscribed yet</h1>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 px-6 h-full">
      <div className="flex items-center justify-between w-full">
        <h1 className="text-xl font-semibold">Current Plan Summary</h1>
        {!subscription?.is_upgrade_scheduled &&
          !isFetchingSubscription &&
          !subscription?.free_subscriber && (
            <Button className="bg-black text-white rounded-full" size="sm" onPress={onOpen}>
              Upgrade
            </Button>
          )}
      </div>
      <Card className="rounded shadow-sm border-1 border-slate-100">
        <CardBody className="pt-3 pb-5 px-4">
          <div className="mb-7 flex gap-28 text-sm">
            <p className="flex items-center text-gray-500">
              Plan:&nbsp;
              <Skeleton className="rounded-full min-w-10 px-1" isLoaded={!isFetchingSubscription}>
                <span className="capitalize text-black">{subscription?.name}</span>
              </Skeleton>
            </p>
            {!subscription?.free_subscriber && (
              <>
                <p className="flex items-center text-gray-500">
                  Billing Cycle:&nbsp;
                  <Skeleton
                    className="rounded-full min-w-10 px-1"
                    isLoaded={!isFetchingSubscription}
                  >
                    <span className="capitalize text-black">{subscription?.recurringType}</span>
                  </Skeleton>
                </p>
                <p className="flex items-center text-gray-500">
                  Plan Cost:&nbsp;
                  <Skeleton
                    className="rounded-full min-w-10 px-1"
                    isLoaded={!isFetchingSubscription}
                  >
                    <span className="uppercase text-black">
                      {subscription?.currency} {subscription?.amount}
                    </span>
                  </Skeleton>
                </p>
              </>
            )}
          </div>
          <Skeleton isLoaded={!isFetchingSubscription}>
            <Progress
              isStriped
              color="default"
              size="lg"
              radius="lg"
              className="progress-loader"
              classNames={{
                base: 'w-full',
                track: 'bg-[#F0F0F0]',
                indicator: 'bg-gradient-to-r from-[#2D62A2] to-[#BAD86D]',
                label: 'text-xs font-medium',
                value: 'text-xs font-medium',
              }}
              label="POINTS"
              value={valuePercent()}
              valueLabel={`${subscription?.used_credits || '0'} of ${
                subscription?.total_purchased_credits || '0'
              } Credit Points Used`}
              showValueLabel={true}
            />
          </Skeleton>
        </CardBody>
      </Card>
      <div className="grow">
        <Invoice toShowPricing={Boolean(subscription?.free_subscriber)} />
      </div>
      {subscription && (
        <UpgradeSubscription
          subscription={subscription}
          isOpen={isOpen}
          plans={upgradePlan()}
          isFetchingPlans={isFetchingPlans}
          onCloseSubscription={(status: string) => {
            if (status === 'skip') onClose();
            else {
              onClose();
            }
          }}
        />
      )}
    </div>
  );
};
